import {Routes} from "constans/routes";
import i18n from 'plugins/i18n';

export const pageNavLinks = [
  {
    name: i18n.t("page_nav.home"),
    path: Routes.HOME,
  },
  {
    name: i18n.t("page_nav.news"),
    path: Routes.NEWS,
  },
  {
    name: i18n.t("page_nav.about"),
    path: Routes.ABOUT,
  },

  {
    name: i18n.t("page_nav.contact"),
    path: Routes.CONTACT,
  },
];
